import React from 'react';
import { NavLink } from 'react-router-dom';
import MaterialIcon from './MaterialIcon';

import '../styles/ComponentStyles/BottomMenuItem.css';

function NavLabelText(text) {
	return text.toLowerCase().trim();
}

function GetClassesByState(isActive, isPending) {
	let text = 'bottom-nav-link text-center text-decoration-none text-white ';
	if (isActive) text += ' active';
	if (isPending) text += ' pending';
	return text;
}

function BottomMenuItem({ ToPath, IconType, Icon, Text }) {
	return (
		<>
			<NavLink
				to={ToPath}
				className={({ isActive, isPending }) => {
					return GetClassesByState(isActive, isPending);
				}}
			>
				<div style={{ height: '1.75rem' }}>
					<MaterialIcon
						IconType={IconType}
						Icon={Icon}
						className={''}
						style={{
							fontSize: '2rem',
						}}
					/>
				</div>
				<div>
					<span className='text-capitalize text-nav-label'>{NavLabelText(Text ?? 'Label')}</span>
				</div>
			</NavLink>
		</>
	);
}

export default BottomMenuItem;
