import '../styles/ComponentStyles/Loading.css';
import MaterialIcon from './MaterialIcon';
import Logo from './Logo';

function Loading() {
	return (
		<div className='w-100 h-100 bg-secondary'>
			<MaterialIcon Icon={'progress_activity'} className={'text-light loading_icon loading_icon_outer'} />
			<Logo className='loading_logo' />
		</div>
	);
}

export default Loading;
