import { useState } from 'react';
import src from '../images/PC_Icon.png';

function Logo({ className, style }) {
	const [photoLoaded, setPhotoLoaded] = useState(false);
	return (
		<img
			src={src}
			alt='Prestige: Connected'
			className={'PC_Icon d-inline-block max-h-100 max-w-100 ' + (className ?? '')}
			style={{ ...{ transition: '1.25s ease-out', opacity: photoLoaded ? 1 : 0 }, ...style }}
			onLoad={() => setPhotoLoaded(true)}
		/>
	);
}

export default Logo;
