import '../styles/ComponentStyles/Friends.css';
import { useEffect, useState } from 'react';
import { collection, query, where, and, or } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Modal, Button, Container, Row, Col, Card, CardHeader, CardTitle, CardBody, ButtonToolbar, ButtonGroup, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useAnalytics, useDb } from '../App';
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../Components/UserIcon';
import MaterialIcon from '../Components/MaterialIcon';
import FormRange from 'react-bootstrap/esm/FormRange';

function Friends({ createRequestCallback, userProfile, userProfilePrivate }) {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Friends' });
	}, []);

	const navigate = useNavigate();

	const friends = userProfilePrivate.User_Friends;
	const [friendData, friendDataLoading] = useCollectionData(
		friends && friends.length > 0 ? query(collection(useDb, 'Users'), and(where('User_UUID', 'in', friends), where('User_UUID', '!=', userProfile.User_UUID))) : ''
	);

	const [requestData, requestDataLoading] = useCollectionData(
		query(collection(useDb, 'Requests'), and(where('Request_Open', '==', true), or(where('Request_From_UUID', '==', userProfile.User_UUID), where('Request_To_UUID', '==', userProfile.User_UUID))))
	);

	const [sort, setSort] = useState('name');
	const [sortDir, setSortDir] = useState('dsc');

	const [sendRequestTo, setSendRequestTo] = useState();
	const [prestigeToSend, setPrestigeToSend] = useState(0);

	const [showModal, setShowModal] = useState(false);

	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);

	const handleSendRequest = () => {
		if (sendRequestTo && sendRequestTo.User_UUID) {
			createRequestCallback(sendRequestTo.User_UUID, prestigeToSend, (response) => {
				// TODO: Update response status
				//console.log('Request response: ', response);
			});
		}
		setSendRequestTo(undefined);
		setPrestigeToSend(0);
	};

	const handleRangeChange = (value) => {
		document.querySelector('#requestRange').value = value;
		setPrestigeToSend(parseInt(value) || 0);
	};

	const handleNotifyClick = (friend) => {
		setPrestigeToSend(0);
		friend.shownName = friend.displayName;
		setSendRequestTo(friend);
		handleShowModal();
	};

	const getSortedFriendsList = (friendsData) => {
		let sorted = [...friendsData];
		let dir = sortDir === 'dsc' ? -1 : 1;

		// TODO: Add support for sorting by request status
		// TODO: Add support for sorting by request timestamp
		switch (sort) {
			case 'prestige':
				sorted = sorted.sortByProperties([{ prop: 'User_Prestige', dir: dir }]);
				break;
			case 'name':
				sorted = sorted.sortByProperties([{ prop: 'User_Display_Name', dir: dir * -1 }]);
				break;
			default:
				sorted = sorted.sortByProperties([{ prop: 'User_Prestige', dir: dir }]);
				break;
		}

		let output = friendsData.map((friend) => {
			return { friend: friend, position: sorted.indexOf(friend) };
		});

		return output;
	};

	const getRequestForFriend = (friend_uuid) => {
		return getRequestFromFriend(friend_uuid) ?? getRequestToFriend(friend_uuid);
	};

	const getRequestFromFriend = (friend_uuid) => {
		return requestData.find((request) => request.Request_From_UUID === friend_uuid);
	};

	const getRequestToFriend = (friend_uuid) => {
		return requestData.find((request) => request.Request_To_UUID === friend_uuid);
	};

	const handleSetSortDir = () => {
		if (sortDir === 'asc') {
			setSortDir('dsc');
		} else if (sortDir === 'dsc') {
			setSortDir('asc');
		} else {
			setSortDir('dsc');
		}
	};

	const handleSetSort = (newSort) => {
		if (sort !== newSort) {
			setSort(newSort);
		}
	};

	return (
		<>
			<Container fluid className='w-100 h-100 p-0 bg-white'>
				<Row className='h-auto min-h-100 justify-content-center pt-4'>
					{friends && friends.length <= 0 && (
						<Col>
							<Row>
								<Col>
									<h1 className='text-center'>
										You Haven't Connected
										<br />
										with anyone yet
									</h1>
								</Col>
							</Row>
							<Row>
								<Col className='mt-4'>
									<Row className='justify-content-center'>
										<Col xs='auto'>
											<Button
												variant='secondary'
												size='lg'
												className='text-white text-capitalize'
												onClick={() => {
													navigate('/connect');
												}}
											>
												Get Started With
												<br />
												Your First Connection
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					)}
					{!friendDataLoading && friendData && friendData.length > 0 && (
						<Col>
							<Row className='justify-content-center'>
								<Card className='w-100 border-0'>
									<CardHeader className='border-0 bg-white'>
										<CardTitle className='m-0'>
											<h3 className='text-center m-0'>Connections</h3>
										</CardTitle>
									</CardHeader>
									<CardBody>
										<Row className='justify-content-center'>
											<Col xs='auto'>
												<ButtonToolbar>
													<div className='btn-group me-3' role='group'>
														<input type='radio' className='btn-check' name='sortRadio' id='btnRadioName' autoComplete='off' defaultChecked />
														<label className='btn btn-outline-primary' htmlFor='btnRadioName' onClick={() => handleSetSort('name')}>
															Name
														</label>

														<input type='radio' className='btn-check' name='sortRadio' id='btnRadioPrestige' autoComplete='off' />
														<label className='btn btn-outline-primary' htmlFor='btnRadioPrestige' onClick={() => handleSetSort('prestige')}>
															Prestige
														</label>
													</div>

													<Button onClick={() => handleSetSortDir()}>
														{sort === 'prestige' ? (
															sortDir === 'dsc' ? (
																<>
																	<MaterialIcon Icon='numbers' />
																	<MaterialIcon Icon='sort' />
																</>
															) : (
																<>
																	<MaterialIcon Icon='numbers' />
																	<MaterialIcon Icon='sort' style={{ transform: 'scaleY(-1)' }} />
																</>
															)
														) : sortDir === 'dsc' ? (
															<>
																<MaterialIcon Icon='sort_by_alpha' />
																<MaterialIcon Icon='sort' />
															</>
														) : (
															<>
																<MaterialIcon Icon='sort_by_alpha' />
																<MaterialIcon Icon='sort' style={{ transform: 'scaleY(-1)' }} />
															</>
														)}
													</Button>
												</ButtonToolbar>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<ListGroup className='list-group-flush position-relative'>
									{getSortedFriendsList(friendData).map(({ friend, position }) => {
										return (
											<ListGroupItem
												data-key={friend.User_UUID}
												key={friend.User_UUID}
												style={{ top: `${65 * position}px`, '--animation-offset': friendData.length - (friendData.length - position) }}
												className='Friend-List-Item w-100 px-4 border-0'
											>
												<Row>
													<Col xs='auto'>
														<Row>
															<Col>
																<UserIcon photoUrl={friend.User_Photo_URL} className={'text-mid'} style={{ height: '3rem', width: '3rem' }} />
															</Col>
														</Row>
													</Col>

													<Col className='h-100 text-truncate' title={`${friend.User_Display_Name}\nPrestige: ${friend.User_Prestige || 0}`}>
														<span className='brand-text'>{friend.User_Display_Name}</span>
														<br />
														<span>
															Prestige: <span className='text_pop'>{friend.User_Prestige || 0}</span>
														</span>
													</Col>

													<Col xs='auto' className='h-100'>
														{!requestDataLoading && getRequestForFriend(friend.User_UUID) ? (
															getRequestFromFriend(friend.User_UUID) ? (
																<Button
																	variant='outline-secondary'
																	size='lg'
																	className='border-0'
																	onClick={() => {
																		// TODO: Handle click when it's a FROM request
																	}}
																>
																	<MaterialIcon Icon={'featured_seasonal_and_gifts'} className='fs-3' />
																</Button>
															) : (
																getRequestToFriend(friend.User_UUID) && (
																	<Button
																		variant='outline-secondary'
																		size='lg'
																		className='border-0'
																		onClick={() => {
																			// TODO: Handle click when it's a TO request
																		}}
																	>
																		<MaterialIcon Icon={'hourglass'} className='fs-3' />
																	</Button>
																)
															)
														) : (
															<Button
																variant='secondary'
																size='lg'
																className='text-white'
																onClick={() => {
																	handleNotifyClick(friend);
																}}
															>
																<MaterialIcon Icon={'volunteer_activism'} className='fs-3' />
															</Button>
														)}
													</Col>
												</Row>
											</ListGroupItem>
										);
									})}
								</ListGroup>
							</Row>
						</Col>
					)}
				</Row>
			</Container>

			{sendRequestTo && (
				<Modal show={showModal} onHide={handleCloseModal} fullscreen={true} centered>
					<Modal.Header closeButton>
						<Modal.Title>Meet-Up Request</Modal.Title>
					</Modal.Header>
					<Modal.Body className='bg-light' style={{ overflowX: 'hidden' }}>
						<Card className='mb-3'>
							<CardHeader className='border-0'>
								<CardTitle className='m-0'>
									<h3 className='text-center m-0'>Send a meet-up request to:</h3>
								</CardTitle>
							</CardHeader>
							<CardBody>
								<Row className='justify-content-center'>
									<Col xs='auto'>
										<Row>
											<Col xs='auto'>
												<Row>
													<Col>
														<UserIcon photoUrl={sendRequestTo.User_Photo_URL} className={'text-mid'} style={{ height: '3rem', width: '3rem' }} />
													</Col>
												</Row>
											</Col>

											<Col className='h-100 text-truncate' title={`${sendRequestTo.User_Display_Name}\nPrestige: ${sendRequestTo.User_Prestige || 0}`}>
												<span className='brand-text'>{sendRequestTo.User_Display_Name}</span>
												<br />
												<span>
													Prestige: <span className='text_pop'>{sendRequestTo.User_Prestige || 0}</span>
												</span>
											</Col>
										</Row>
									</Col>
								</Row>
							</CardBody>
						</Card>

						<Card className='mb-3'>
							<CardHeader className='border-0'>
								<CardTitle className='m-0'>
									<h5 className='text-center m-0'>
										Would you like to send some of
										<br />
										your prestige with this request?
									</h5>
								</CardTitle>
							</CardHeader>
							<CardBody>
								<Row>
									<Col xs='auto'>
										<label htmlFor='requestRange' className='form-label text-capitalize'>
											Select Prestige to send
										</label>
									</Col>
									<Col className='text-end'>
										<span className='text_pop'>
											{prestigeToSend} / {userProfile.User_Prestige}
										</span>
									</Col>
								</Row>
								<Row className='mb-4'>
									<Col>
										<FormRange
											id='requestRange'
											min='0'
											max={userProfile.User_Prestige}
											step='1'
											defaultValue='0'
											onChange={(e) => {
												handleRangeChange(e.target.value);
											}}
										/>
									</Col>
								</Row>
								<Row className='justify-content-center mb-2'>
									<Col xs='auto'>
										<ButtonToolbar>
											<ButtonGroup>
												{[0, 5, 10, 50, 100, 500, 1000].map((value, i) => {
													return (
														userProfile.User_Prestige >= value && (
															<Button
																key={i}
																variant={prestigeToSend === value ? 'secondary' : 'outline-secondary'}
																className={prestigeToSend === value ? 'text-white' : ''}
																onClick={() => {
																	handleRangeChange(value);
																}}
															>
																{value}
															</Button>
														)
													);
												})}
											</ButtonGroup>
										</ButtonToolbar>
									</Col>
								</Row>
							</CardBody>
						</Card>

						<Card className='text-center'>
							<CardBody>
								<span className='fst-italic fw-light small'>
									Any prestige that you choose to send will be deducted from you immediately, but will only be received by the recipient the next time that you two connect
								</span>
							</CardBody>
						</Card>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='outline-secondary' onClick={handleCloseModal}>
							Cancel
						</Button>
						<Button
							variant='primary'
							onClick={() => {
								handleCloseModal();
								handleSendRequest();
							}}
						>
							Send Request
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
}

export default Friends;
