import '../styles/ComponentStyles/Auth.css';
import { useEffect } from 'react';
import Logo from '../Components/Logo';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { useAuth, useDb, useAnalytics } from '../App';

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { setDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import GoogleButton from 'react-google-button';

function Auth({ loginCallback }) {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Login' });
	}, []);

	const navigate = useNavigate();

	const signInWithGoogle = () => {
		signInWithPopup(useAuth, new GoogleAuthProvider())
			.then((result) => {
				if (result.user) {
					const { uid, displayName, photoURL, email, phoneNumber } = result.user;

					const publicDocRef = doc(useDb, 'Users', uid);
					const privateDocRef = doc(useDb, 'Users', uid, 'Private', 'Private_Data');

					getDoc(publicDocRef)
						.then((publicDoc) => {
							if (publicDoc.exists()) {
								updateDoc(publicDocRef, {
									User_Display_Name: displayName,
									User_Photo_URL: photoURL,
								}).then(() => {
									loginCallback();
									logEvent(useAnalytics, 'login', publicDoc.data());

									getDoc(privateDocRef)
										.then((privateDoc) => {
											if (privateDoc.exists()) {
												const privateData = privateDoc.data();
												if (privateData.User_DateTimeFormat === undefined || privateData.User_DateTimeFormat === null) {
													updateDoc(privateDocRef, {
														User_DateTimeFormat: Intl.DateTimeFormat().resolvedOptions(),
													}).then(() => {
														logEvent(useAnalytics, 'User_DateTimeFormat_updated', privateData);
													});
												}
											}
										})
										.catch((error) => {
											logEvent(useAnalytics, 'error', {
												userProfilePrivate_error: error,
											});
										});
								});
							} else {
								setDoc(publicDocRef, {
									User_UUID: uid,
									User_Display_Name: displayName,
									User_Photo_URL: photoURL,
									User_Prestige: 0,
								}).then(() => {
									var now = Date(Date.now());
									setDoc(privateDocRef, {
										User_UUID: uid,
										User_Created_Timestamp: now,
										User_Modified_Timestamp: now,
										User_Last_Login_Timestamp: now,
										User_DateTimeFormat: Intl.DateTimeFormat().resolvedOptions(),
										User_Email: email,
										User_Phone: phoneNumber,
										User_Friends: [],
										User_Prestige: 0,
									}).then(() => {
										// First time sign-up
										logEvent(useAnalytics, 'sign_up', publicDoc.data());
										logEvent(useAnalytics, 'login', publicDoc.data());
									});
								});
							}
						})
						.catch((error) => {
							logEvent(useAnalytics, 'error', {
								userProfile_error: error,
							});
						});

					navigate('/');
				}
			})
			.catch((error) => {
				logEvent(useAnalytics, 'error', { login_error: error });
			});
	};

	return (
		<Row className='Auth w-100 h-100 row m-0 align-content-center bg-primary'>
			<Col xs={12} sm={6} className='mb-4 max-h-50'>
				<Row className='h-100 justify-content-center'>
					<Col xs={'auto'} className='h-100'>
						<Logo className='login_Logo' />
					</Col>
				</Row>
			</Col>
			<Col>
				<Row className='h-100 justify-content-center align-content-center'>
					<Col xs='auto'>
						<Row className='align-content-center mb-4'>
							<Col>
								<blockquote className='blockquote text-white text-center px-2'>
									<p className='text-uppercase' style={{ fontSize: 'calc((100vw + 100vh)/2 * 0.04)' }}>
										<span className='brand_font brand_font_lg' style={{ fontSize: 'calc((100vw + 100vh)/2 * 0.06)' }}>
											P
										</span>
										restige:&nbsp;
										<span className='brand_font brand_font_lg' style={{ fontSize: 'calc((100vw + 100vh)/2 * 0.06)' }}>
											C
										</span>
										onnected
									</p>

									<p className='text-uppercase' style={{ fontSize: 'calc((100vw + 100vh)/2 * 0.03)' }}>
										Promoting high quality
										<br />
										face&#8209;to&#8209;face interactions
										<br />
										in a disconnected world
									</p>
								</blockquote>
							</Col>
						</Row>
						<Row className='align-content-center'>
							<Col>
								<Row className='justify-content-center'>
									<Col xs='auto'>
										<GoogleButton className='login_Button rounded-pill overflow-hidden' type='light' onClick={signInWithGoogle} />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}

export default Auth;
