import React from 'react';

// https://stackoverflow.com/questions/3552461/how-do-i-format-a-date-in-javascript

function DateTime({ dateTime, locale, timeZone }) {
	const displayDateTime = new Date(dateTime);
	const displayLocale = locale ?? 'en-US';
	const displayTimeZone = timeZone ?? 'America/New_York';

	return (
		<div className='h-100 text-end'>
			<div className='h-50'>{displayDateTime.toLocaleString(displayLocale, { year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
			<div className='h-50'>{displayDateTime.toLocaleString(displayLocale, { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: displayTimeZone })}</div>
		</div>
	);
}

export default DateTime;
