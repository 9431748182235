import '../styles/ComponentStyles/Friends.css';
import { useEffect, useState } from 'react';
import { collection, query, where, and, or, limit, orderBy } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Button, Container, Row, Col, Card, CardHeader, CardTitle, CardBody, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useAnalytics, useDb } from '../App';
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../Components/UserIcon';
import DateTime from '../Components/DateTime';

function History({ userProfile, userProfilePrivate }) {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'History' });
	}, []);

	const navigate = useNavigate();

	const [friends, setFriends] = useState([]);

	const [friendData, friendDataLoading] = useCollectionData(
		friends && friends.length > 0 ? query(collection(useDb, 'Users'), and(where('User_UUID', 'in', friends), where('User_UUID', '!=', userProfile.User_UUID))) : ''
	);

	const [historicalConnectionData, historicalConnectionDataLoading] = useCollectionData(
		query(
			collection(useDb, 'HistoricalConnections'),
			or(where('Connection_UUID_1', '==', userProfile.User_UUID), where('Connection_UUID_2', '==', userProfile.User_UUID)),
			orderBy('HistoricalConnection_Timestamp'),
			limit(25)
		)
	);

	useEffect(() => {
		if (!historicalConnectionDataLoading && historicalConnectionData && historicalConnectionData.length > 0) {
			setFriends(historicalConnectionData.map((hc) => (hc.Connection_UUID_1 !== userProfile.User_UUID ? hc.Connection_UUID_1 : hc.Connection_UUID_2)));
		}
	}, [historicalConnectionDataLoading, historicalConnectionData, userProfile]);

	return (
		<>
			<Container fluid className='w-100 h-100 p-0 bg-white'>
				<Row className='h-auto min-h-100 justify-content-center pt-4'>
					{!historicalConnectionDataLoading && historicalConnectionData && historicalConnectionData.length <= 0 && (
						<Col>
							<Row>
								<Col>
									<h1 className='text-center'>
										You Haven't Connected
										<br />
										with anyone yet
									</h1>
								</Col>
							</Row>
							<Row>
								<Col className='mt-4'>
									<Row className='justify-content-center'>
										<Col xs='auto'>
											<Button
												variant='secondary'
												size='lg'
												className='text-white text-capitalize'
												onClick={() => {
													navigate('/connect');
												}}
											>
												Get Started With
												<br />
												Your First Connection
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					)}
					<Col>
						<Row className='justify-content-center'>
							<Card className='w-100 border-0'>
								<CardHeader className='border-0 bg-white'>
									<CardTitle className='m-0'>
										<h3 className='text-center m-0'>Connection History</h3>
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Row className='justify-content-center'>
										<Col xs='auto'></Col>
									</Row>
								</CardBody>
							</Card>
							<ListGroup className='list-group-flush position-relative'>
								{!friendDataLoading &&
									friendData &&
									!historicalConnectionDataLoading &&
									historicalConnectionData &&
									historicalConnectionData
										.map((hc) => {
											return { ...hc, HistoricalConnection_DisplayTimestamp: new Date(hc.HistoricalConnection_DisplayTimestamp) };
										})
										.sortByProperties([{ prop: 'HistoricalConnection_DisplayTimestamp', dir: -1 }])
										.map((historicalConnection, position) => {
											const friendUUID =
												historicalConnection.Connection_UUID_1 !== userProfile.User_UUID ? historicalConnection.Connection_UUID_1 : historicalConnection.Connection_UUID_2;
											let friend = friendData.find((f) => f.User_UUID === friendUUID);

											return (
												<ListGroupItem
													data-key={`${historicalConnection.Connection_UUID}-${historicalConnection.HistoricalConnection_DisplayTimestamp}-${historicalConnection.HistoricalConnection_Timestamp}`}
													key={`${historicalConnection.Connection_UUID}-${historicalConnection.HistoricalConnection_DisplayTimestamp}-${historicalConnection.HistoricalConnection_Timestamp}`}
													style={{ top: `${65 * position}px`, '--animation-offset': historicalConnectionData.length - (historicalConnectionData.length - position) }}
													className='Friend-List-Item w-100 px-4 border-0'
												>
													<Row>
														<Col xs='auto'>
															<Row>
																<Col>
																	<UserIcon photoUrl={friend.User_Photo_URL} className={'text-mid'} style={{ height: '3rem', width: '3rem' }} />
																</Col>
															</Row>
														</Col>

														<Col className='h-100 text-truncate' title={`${friend.User_Display_Name}\nPrestige: ${friend.User_Prestige || 0}`}>
															<span className='brand-text'>{friend.User_Display_Name}</span>
															<br />
															<span>
																Prestige: <span className='text_pop'>{friend.User_Prestige || 0}</span>
															</span>
														</Col>

														<Col xs='auto' className='h-100'>
															<DateTime
																dateTime={historicalConnection.HistoricalConnection_DisplayTimestamp}
																locale={userProfilePrivate?.User_DateTimeFormat?.locale}
																timeZone={userProfilePrivate?.User_DateTimeFormat?.timeZone}
															/>
														</Col>
													</Row>
												</ListGroupItem>
											);
										})}
							</ListGroup>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default History;
