import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics, useDb } from '../App';
import MaterialIcon from '../Components/MaterialIcon';
import { clearLocationWatch, watchCurrentLocation } from '../API/Utilities';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { doc, collection, query, where, or } from 'firebase/firestore';
import UserIcon from '../Components/UserIcon';

function Connect({ userProfile, handleStartLookingForConnection, handleStopLookingForConnection, handleEngageConnection }) {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Connect' });
	}, []);

	const [searching, setSearching] = useState(false);
	const [searchingData, searchingDataLoading] = useDocumentData(doc(useDb, 'Searching', userProfile.User_UUID));

	const startWatchingLocation = useCallback(() => {
		watchCurrentLocation(
			(position) => {
				console.log(position);
				if (position && position.coords.latitude && position.coords.longitude) {
					handleStartLookingForConnection(position.coords.latitude, position.coords.longitude, (result) => {
						if (result && result.data.success) {
							setSearching(true);
						}
					});
				}
			},
			(error) => {
				console.log(error);
				clearLocationWatch();
				handleStopLookingForConnection((result) => {
					result && result.data.success && setSearching(false);
				});
			}
		);
	}, [handleStartLookingForConnection, handleStopLookingForConnection]);

	const stopWatchingLocation = () => {
		clearLocationWatch();
		handleStopLookingForConnection((result) => {
			result && result.data.success && setSearching(false);
		});
	};

	useEffect(() => {
		if (!searchingDataLoading) {
			if (searchingData && searchingData.Searching_Available) {
				setSearching(true);
				startWatchingLocation();
			} else {
				setSearching(false);
			}
		} else {
			setSearching(false);
		}
	}, [searchingData, searchingDataLoading, setSearching, startWatchingLocation]);

	const [connectionUUIDs, setConnectionUUIDs] = useState([]);

	const [connectionsData, connectionsDataLoading] = useCollectionData(
		query(collection(useDb, 'Connections'), or(where('Connection_UUID_1', '==', userProfile.User_UUID), where('Connection_UUID_2', '==', userProfile.User_UUID)))
	);

	const [connectionUsers, connectionUsersLoading] = useCollectionData(
		connectionUUIDs && connectionUUIDs.length > 0 ? query(collection(useDb, 'Users'), where('User_UUID', 'in', connectionUUIDs)) : ''
	);

	useEffect(() => {
		if (!connectionsDataLoading) {
			if (connectionsData && connectionsData.length > 0) {
				setConnectionUUIDs(connectionsData.map((connection) => (connection.Connection_UUID_1 !== userProfile.User_UUID ? connection.Connection_UUID_1 : connection.Connection_UUID_2)));
			} else {
				setConnectionUUIDs([]);
			}
		}
	}, [connectionsDataLoading, connectionsData, userProfile]);

	const [connections, setConnections] = useState([]); // [{ user, connectionData, engaged }]

	useEffect(() => {
		if (!connectionUsersLoading) {
			if (connectionUsers && connectionUsers.length > 0) {
				setConnections(
					connectionUsers
						.map((user) => {
							let connectionData = connectionsData.find((data) => data.Connection_UUID_1 === user.User_UUID || data.Connection_UUID_2 === user.User_UUID);
							if (connectionData !== undefined) {
								let engaged =
									connectionData.Connection_UUID_1 !== userProfile.User_UUID ? connectionData.Connection_UUID_1_Engaged ?? false : connectionData.Connection_UUID_2_Engaged ?? false;
								let waiting =
									connectionData.Connection_UUID_1 === userProfile.User_UUID ? connectionData.Connection_UUID_1_Engaged ?? false : connectionData.Connection_UUID_2_Engaged ?? false;
								return { user, connectionData, engaged, waiting };
							} else {
								return {};
							}
						})
						.filter((connection) => connection.user !== undefined && connection.connectionData !== undefined)
						.sortByProperties([{ prop: 'waiting' }, { prop: 'engaged' }, { prop: 'connectionData.Connection_Timestamp', dir: -1 }])
				);
			} else {
				setConnections([]);
			}
		}
	}, [connectionUsersLoading, connectionUsers, userProfile, connectionsData]);

	const handleSearchButtonClick = () => {
		if (searching) {
			stopWatchingLocation();
		} else {
			startWatchingLocation();
		}
	};

	const handleConnectClick = (connection) => {
		if (connection && connection.connectionData && connection.connectionData.Connection_UUID && connection.waiting !== undefined && !connection.waiting) {
			handleEngageConnection(connection.connectionData.Connection_UUID, (response) => {
				if (response.data.success) {
					stopWatchingLocation();
				}
			});
		}
	};

	return (
		<>
			<Container fluid className='w-100 h-100 p-0 bg-white'>
				<Row className='h-auto min-h-100 align-content-center justify-content-center'>
					<Col>
						{connections && connections.length > 0 && (
							<ListGroup className='list-group-flush w-100 px-1'>
								{connections.map((connection, index, connections) => {
									return (
										<ListGroupItem
											data-key={connection.connectionData.Connection_UUID}
											key={connection.connectionData.Connection_UUID}
											className={`w-100 px-4 border-1 border-secondary rounded-pill ${connection.engaged ? ' bg-secondary text-white text_pop' : ''}`}
										>
											<Row>
												<Col xs='auto' className='h-100 px-0'>
													<Row>
														<Col>
															<UserIcon photoUrl={connection.user.User_Photo_URL} className={'text-mid'} style={{ height: '3rem', width: '3rem' }} />
														</Col>
													</Row>
												</Col>

												<Col className='h-100 text-truncate' title={`${connection.user.User_Display_Name}\nPrestige: ${connection.user.User_Prestige || 0}`}>
													<span className='brand-text'>{connection.user.User_Display_Name}</span>
													<br />
													<span>
														Prestige: <span className='text_pop'>{connection.user.User_Prestige || 0}</span>
													</span>
												</Col>

												<Col xs='auto' className='h-100 px-0'>
													{connection.waiting ? (
														<Button
															variant='outline-secondary'
															size='lg'
															className='border-0 text-white border-white rounded-circle px-2'
															style={{ width: '48px' }}
															onClick={() => {
																// TODO: Handle click to cancel maybe
															}}
														>
															<MaterialIcon Icon={'hourglass'} className='fs-3' />
														</Button>
													) : (
														<Button
															variant='primary'
															size='lg'
															className='text-white border-white rounded-circle px-2'
															style={{ width: '48px' }}
															onClick={() => {
																handleConnectClick(connection);
															}}
														>
															<MaterialIcon Icon={'handshake'} />
														</Button>
													)}
												</Col>
											</Row>
										</ListGroupItem>
									);
								})}
							</ListGroup>
						)}
					</Col>
				</Row>

				<Row className='justify-content-center position-relative pb-4' style={{ bottom: '0px', transform: 'translateY(-100%)' }}>
					<Col xs='auto'>
						<Button variant='secondary' size='lg' className='text-white' onClick={handleSearchButtonClick}>
							<MaterialIcon Icon={'distance'} className='me-3' />
							{searching ? <span className='d-inline-block'>Stop Looking For Connections</span> : <span className='d-inline-block'>Start Looking For Connections</span>}
						</Button>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Connect;
