import React, { createContext } from 'react';
import './API/Extensions';

// Import page router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Import Firebase functions
import { initializeApp } from 'firebase/app';
import {
	getFirestore,
	doc,
	//getDoc,
	// updateDoc,
	// serverTimestamp,
	// query,
	// collection,
	// where,
	// orderBy,
} from 'firebase/firestore';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getRemoteConfig, fetchAndActivate, getBoolean } from 'firebase/remote-config';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { useAuthState } from 'react-firebase-hooks/auth';

import {
	//useCollection,
	//useDocument,
	useDocumentData,
	// useCollectionData
} from 'react-firebase-hooks/firestore';

// import { async } from "@firebase/util";

// Import CSS
import './index.css';
import './styles/AllStyles.scss';

import Loading from './Components/Loading';

// Import all Layouts
import * as Layouts from './Layouts/_AllLayouts';

// Import route loaders
// import { loader as loader_tiles } from './Routes/Tiles';

// Import all Routes
import * as Routes from './Routes/_AllRoutes';

// Define Firebase config values
const firebaseConfig = {
	apiKey: 'AIzaSyC5yrfSlPAP0WI7RJr0WWRwh7uBsf0NY8c',
	authDomain: 'prestige-connected-revamp.firebaseapp.com',
	databaseURL: 'https://prestige-connected-revamp-default-rtdb.firebaseio.com',
	projectId: 'prestige-connected-revamp',
	storageBucket: 'prestige-connected-revamp.appspot.com',
	messagingSenderId: '897881774772',
	appId: '1:897881774772:web:2e1955ba6cbd88b7b315e1',
	measurementId: 'G-SXH7DYSV3G',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

// Initialize Remote Config
const remoteConfig = getRemoteConfig(app);
// remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
let remoteConfigDefaults = require('./remote_config_defaults.json');
remoteConfig.defaultConfig = remoteConfigDefaults;
fetchAndActivate(remoteConfig);

// Initialize App Context
const AppContext = createContext({});

// Initialize HTTPS Callable Firebase Functions
const recordLoginTimestamp = httpsCallable(functions, 'userprofile-recordLoginTimestamp');
const handleRecordLoginTimestamp = (callback) => {
	recordLoginTimestamp()
		.then((result) => {
			return typeof callback === 'function' && callback(result);
		})
		.catch((error) => {});
};

const createMeetUpRequest = httpsCallable(functions, 'requests-createMeetUpRequest');
const handleCreateMeetUpRequest = (uid, prestige, callback) => {
	createMeetUpRequest({ uid, prestige })
		.then((result) => {
			return typeof callback === 'function' && callback(result);
		})
		.catch((error) => {});
};

const startLookingForConnection = httpsCallable(functions, 'connect-startLookingForConnection');
const handleStartLookingForConnection = (latitude, longitude, callback) => {
	startLookingForConnection({ latitude, longitude })
		.then((result) => {
			return typeof callback === 'function' && callback(result);
		})
		.catch((error) => {});
};

const stopLookingForConnection = httpsCallable(functions, 'connect-stopLookingForConnection');
const handleStopLookingForConnection = (callback) => {
	stopLookingForConnection()
		.then((result) => {
			return typeof callback === 'function' && callback(result);
		})
		.catch((error) => {});
};

const engageConnection = httpsCallable(functions, 'connect-engageConnection');
const handleEngageConnection = (Connection_UUID, callback) => {
	engageConnection({ Connection_UUID })
		.then((result) => {
			return typeof callback === 'function' && callback(result);
		})
		.catch((error) => {});
};

// Keep the User object up to date
let User = null;
onAuthStateChanged(auth, (user) => {
	if (user) {
		User = user;
	} else {
		User = null;
	}
});

console.log('DateTimeFormat', Intl.DateTimeFormat().resolvedOptions());

function App() {
	const [user, userLoading, userError] = useAuthState(auth);
	const [userProfile, userProfileLoading] = useDocumentData(doc(db, 'Users', (user && user.uid) || ' '));
	const [userProfilePrivate, userProfilePrivateLoading] = useDocumentData(doc(db, 'Users', (user && user.uid) || ' ', 'Private', 'Private_Data'));

	const betaTestEnabled = getBoolean(remoteConfig, 'beta_test');

	// const [screenStates, setScreenStates] = useState({ Friends: {}, Leaders: {}, Connect: {}, History: {}, Account: {} });

	const router = createBrowserRouter([
		{
			path: '/',
			errorElement: <Routes.ErrorPage />,
			element: userError ? (
				() => {
					throw userError;
				}
			) : userLoading || userProfileLoading ? (
				<Loading />
			) : user && userProfile ? (
				userProfilePrivateLoading ? (
					<Loading />
				) : userProfilePrivate ? (
					<Layouts.Main userProfile={userProfile} userProfilePrivate={userProfilePrivate} betaTestEnabled={betaTestEnabled} />
				) : (
					<Routes.ErrorPage />
				)
			) : (
				<Layouts.Auth loginCallback={handleRecordLoginTimestamp} />
			),
			loader: () => true,
			action: () => {},
			children: [
				{
					errorElement: <Routes.ErrorPage />,
					children: [
						{
							index: true,
							// element: <Routes.Index />,
							element: (
								<Routes.Connect
									userProfile={userProfile}
									handleStartLookingForConnection={handleStartLookingForConnection}
									handleStopLookingForConnection={handleStopLookingForConnection}
									handleEngageConnection={handleEngageConnection}
								/>
							),
							errorElement: <Routes.ErrorPage />,
						},
						{
							path: 'friends',
							element: <Routes.Friends userProfile={userProfile} userProfilePrivate={userProfilePrivate} createRequestCallback={handleCreateMeetUpRequest} />,
							errorElement: <Routes.ErrorPage />,
						},
						{
							path: 'leaders',
							element: <Routes.Leaders userProfile={userProfile} userProfilePrivate={userProfilePrivate} />,
							errorElement: <Routes.ErrorPage />,
						},
						{
							path: 'connect',
							element: <Routes.Connect />,
							errorElement: <Routes.ErrorPage />,
						},
						{
							path: 'history',
							element: <Routes.History userProfile={userProfile} userProfilePrivate={userProfilePrivate} />,
							errorElement: <Routes.ErrorPage />,
						},
						{
							path: 'account',
							element: <Routes.Account userProfile={userProfile} userProfilePrivate={userProfilePrivate} />,
							errorElement: <Routes.ErrorPage />,
						},
						// { // Path with param example
						// 	path: 'contacts/:contactId/edit',
						// 	// element: <Routes.EditContact />,
						// 	loader: contactLoader,
						// 	action: editAction,
						// },
						// { // Action only example
						// 	path: 'contacts/:contactId/destroy',
						// 	errorElement: <div>Oops! There was an error.</div>,
						// 	action: deleteAction,
						// },
						// {
						// 	path: 'tiles',
						// 	element: (
						// 		<AppContext.Provider value={{ screenStates, setScreenStates }}>
						// 			<Routes.Tiles userProfile={userProfile} taskLists={taskLists} />
						// 		</AppContext.Provider>
						// 	),
						// 	loader: loader_tiles,
						// 	errorElement: <Routes.ErrorPage />,
						// },
					],
				},
			],
		},
	]);

	return <RouterProvider router={router} />;
}

export { auth as useAuth, analytics as useAnalytics, db as useDb, User as useUser, AppContext };
export default App;
