import React, { useState } from 'react';
import { Badge, Col, Container, Navbar, Row } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from './Logo';
import MaterialIcon from './MaterialIcon';
import '../styles/ComponentStyles/TopMenu.css';
import UserIcon from './UserIcon';
import DebugConsole from './DebugConsole';

function TopMenu({ userProfile, userProfilePrivate, betaTestEnabled }) {
	const [expanded, setExpanded] = useState();
	const navigate = useNavigate();

	const [showDebugConsole, setShowDebugLog] = useState(false);

	return (
		<>
			<Navbar fixed='top' expand='xs' className={'p-0 top_navbar user-select-none ' + (expanded ? 'top_navbar_opened' : 'top_navbar_closed')} onToggle={(e) => setExpanded(e)}>
				<Container
					fluid
					className='bg-secondary top_navbar_buttons'
					style={{
						height: '65px',
						zIndex: 1030,
					}}
				>
					<Navbar.Brand className='h-100 m-0'>
						<NavLink className='navbar-brand h-100 m-0' to='/'>
							<Logo />
						</NavLink>
					</Navbar.Brand>
					{betaTestEnabled && (
						<Navbar.Text>
							<Badge onClick={() => setShowDebugLog(!showDebugConsole)}>
								<MaterialIcon Icon='experiment' />
								<span className='ms-2'>Experimental</span>
							</Badge>
						</Navbar.Text>
					)}
					<Navbar.Toggle style={{ width: '55px' }}>
						<MaterialIcon Icon={expanded ? 'close' : 'menu'} className={'text-white top_menu_toggle ' + (expanded ? 'top_menu_opened' : 'top_menu_closed')} />
					</Navbar.Toggle>
				</Container>
				<Navbar.Collapse className={'bg-secondary p-3 text-mid top_menu ' + (expanded ? 'top_menu_opened' : 'top_menu_closed')}>
					<Container>
						<Row
							onClick={() => {
								navigate('/account');
							}}
						>
							<Col xs='auto'>
								<UserIcon photoUrl={userProfile.User_Photo_URL} style={{ height: '3rem' }} className={'border-2 text-light'} />
							</Col>
							<Col>
								{userProfile && userProfile.User_Display_Name && (
									<div
										className='text-body h-50'
										style={{
											lineHeight: '2.25rem',
										}}
									>
										{userProfile.User_Display_Name}
									</div>
								)}

								{userProfilePrivate && userProfilePrivate.User_Email && (
									<div
										className='text-body-secondary h-50'
										style={{
											fontSize: '0.75rem',
										}}
									>
										{userProfilePrivate.User_Email}
									</div>
								)}
							</Col>
						</Row>
					</Container>
				</Navbar.Collapse>
				{showDebugConsole && (
					<div className='position-fixed w-100' style={{ zIndex: 1014, top: 65, left: 0, height: 'calc(100vh - 130px)' }}>
						<DebugConsole />
					</div>
				)}
			</Navbar>
		</>
	);
}

export default TopMenu;
