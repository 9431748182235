import '../styles/ComponentStyles/Friends.css';
import { useEffect, useState } from 'react';
import { collection, query, where, and, or, orderBy, limit } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody, ButtonToolbar, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useAnalytics, useDb } from '../App';
import { logEvent } from 'firebase/analytics';
import UserIcon from '../Components/UserIcon';

function Leaders({ userProfile, userProfilePrivate }) {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Leaders' });
	}, []);

	const friends = userProfilePrivate.User_Friends;
	const [friendData, friendDataLoading] = useCollectionData(
		friends && friends.length > 0
			? query(
					collection(useDb, 'Users'),
					or(where('User_UUID', '==', userProfile.User_UUID), and(where('User_UUID', 'in', friends), where('User_UUID', '!=', userProfile.User_UUID))),
					limit(100)
			  )
			: ''
	);

	const [leaders, leadersLoading] = useCollectionData(query(collection(useDb, 'Users'), orderBy('User_Prestige', 'desc'), limit(100)));

	const [filter, setFilter] = useState('global');

	const getListData = () => {
		switch (filter) {
			case 'global':
				return leaders;
			case 'friends':
				return friendData ? friendData.sort((a, b) => ((parseInt(a.User_Prestige) || 0) > (parseInt(b.User_Prestige) || 0) ? -1 : 1)) : friendData;
			default:
				return leaders;
		}
	};

	return (
		<>
			<Container fluid className='w-100 h-100 p-0 bg-white'>
				<Row className='h-auto min-h-100 justify-content-center pt-4'>
					<Col>
						<Row className='justify-content-center'>
							<Card className='w-100 border-0'>
								<CardHeader className='border-0 bg-white'>
									<CardTitle className='m-0'>
										<h3 className='text-center m-0'>Prestige Board</h3>
									</CardTitle>
								</CardHeader>
								{friends && friends.length > 0 && (
									<CardBody>
										<Row className='justify-content-center'>
											<Col xs='auto'>
												<ButtonToolbar>
													<div className='btn-group' role='group'>
														<input type='radio' className='btn-check' name='filterRadio' id='btnRadioAll' autoComplete='off' defaultChecked />
														<label className='btn btn-outline-primary' htmlFor='btnRadioAll' onClick={() => setFilter('global')}>
															Global
														</label>

														<input type='radio' className='btn-check' name='filterRadio' id='btnRadioFriends' autoComplete='off' />
														<label className='btn btn-outline-primary' htmlFor='btnRadioFriends' onClick={() => setFilter('friends')}>
															Connections
														</label>
													</div>
												</ButtonToolbar>
											</Col>
										</Row>
									</CardBody>
								)}
							</Card>
						</Row>
						{!friendDataLoading && !leadersLoading && getListData() && getListData().length > 0 && (
							<ListGroup className='list-group-flush position-relative'>
								{getListData().map((leader, position, list) => {
									return (
										<ListGroupItem
											data-key={leader.User_UUID}
											key={leader.User_UUID}
											style={{ top: `${65 * position}px`, '--animation-offset': (list.length - (list.length - position)) * -1 }}
											className={`Friend-List-Item w-100 px-4 border-0 rounded-pill ${leader.User_UUID === userProfile.User_UUID ? ' bg-secondary text-white text_pop' : ''}`}
											title={`${leader.User_Display_Name}\nPrestige: ${leader.User_Prestige || 0}`}
										>
											<Row>
												<Col xs='1' className='p-0'>
													<div
														className='text-center text_pop'
														style={{
															height: '3rem',
															lineHeight: '3rem',
															fontSize: (2.5 - position * 0.8 > 1.25 ? 2.5 - position * 0.8 : 1.25) + 'em',
														}}
													>
														{position + 1}
													</div>
												</Col>

												<Col xs='auto' className='pe-1'>
													<Row>
														<Col>
															<UserIcon photoUrl={leader.User_Photo_URL} className={'text-mid'} style={{ height: '3rem', width: '3rem' }} />
														</Col>
													</Row>
												</Col>

												<Col className='h-100 ps-1 text-truncate'>
													<span
														className='brand-body-font'
														style={{
															height: '3rem',
															lineHeight: '3rem',
														}}
													>
														{leader.User_Display_Name}
													</span>
												</Col>

												<Col xs='auto' className='px-0'>
													<span
														className='text_pop'
														style={{
															height: '3rem',
															lineHeight: '3rem',
															fontSize: (1.5 - position * 0.25 > 1.25 ? 1.5 - position * 0.25 : 1.25) + 'em',
														}}
													>
														{leader.User_Prestige || 0}
													</span>
												</Col>
											</Row>
										</ListGroupItem>
									);
								})}
							</ListGroup>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Leaders;
