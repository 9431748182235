import { Row, Col, Navbar } from 'react-bootstrap';
import BottomMenuItem from './BottomMenuItem';

function BottomMenu() {
	return (
		<Navbar
			fixed='bottom'
			className='w-100 bg-secondary user-select-none'
			style={{
				height: '65px',
				borderTop: '1px solid var(--bs-gray-300)',
			}}
		>
			<Row className='w-100 p-0 m-0 justify-content-around'>
				<Col className='p-0'>
					<BottomMenuItem ToPath='/friends' Icon='group' Text='Friends' />
				</Col>
				<Col className='p-0'>
					<BottomMenuItem ToPath='/leaders' Icon='trophy' Text='Leaders' />
				</Col>
				<Col className='p-0'>
					<BottomMenuItem ToPath='/' Icon='handshake' Text='Connect' />
				</Col>
				<Col className='p-0'>
					<BottomMenuItem ToPath='/history' Icon='calendar_clock' Text='History' />
				</Col>
				<Col className='p-0'>
					<BottomMenuItem ToPath='/account' Icon='account_circle' Text='Account' />
				</Col>
			</Row>
		</Navbar>
	);
}

export default BottomMenu;
