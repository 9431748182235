import { cloneAsObject, compare } from './Utilities';

// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'sortByProperties', {
	// Example function call
	// arrayVar.sortByProperties([{ prop: 'objectPropertyName_1' }, { prop: 'objectPropertyName_2.subPropertyName_1', dir: -1 }])
	value: function (sorts) {
		// sorts is an array of sorting option objects is the following format: sort = { prop: 'objectPropertyName', dir: 1 }
		// sort.prop is a string of a property name in JS object property notation e.g. 'property.subPropertry'
		// sort.dir is a numerical value that the sort compare is multiplid by, i.e. 1 is ascending and -1 is descending
		return this.sort((a, b) =>
			compare(
				sorts.map((sort) => compare(a[sort.prop ?? ''], b[sort.prop ?? '']) * (sort.dir ?? 1)),
				sorts.map((sort) => compare(b[sort.prop ?? ''], a[sort.prop ?? '']) * (sort.dir ?? 1))
			)
		);
	},
	enumerable: false,
});

function debugHistory() {
	let self = this;
	self.log = [];
	self.warn = [];
	self.error = [];
	self.debug = [];
	self.info = [];

	self.data = () => {
		return {
			log: self.log,
			warn: self.warn,
			error: self.error,
			debug: self.debug,
			info: self.info,
		};
	};

	Object.defineProperty(self, 'observe', {
		value: function (callback) {
			if (typeof callback === 'function') self.observerCallback = callback;
		},
		enumerable: false,
	});

	let observerCallback = null;
	Object.defineProperty(self, 'observerCallback', {
		get: function () {
			return observerCallback;
		},
		set: function (value) {
			observerCallback = value;
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'pushToHistory', {
		value: function (category, data) {
			self[category] && self[category].push(data);
			self[category] && self[category].length > 100 && self[category].sortByProperties([{ prop: 'Timestamp', dir: -1 }]).pop();
			self.saveToStorage();
			self.observerCallback && self.observerCallback.call(this, self);
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'saveToStorage', {
		value: function () {
			localStorage.setItem('debug_history', JSON.stringify(cloneAsObject(self.data())));
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'loadFromStorage', {
		value: function () {
			let savedHistory = JSON.parse(localStorage.getItem('debug_history'));

			self.log = savedHistory?.log ?? [];
			self.warn = savedHistory?.warn ?? [];
			self.error = savedHistory?.error ?? [];
			self.debug = savedHistory?.debug ?? [];
			self.info = savedHistory?.info ?? [];

			self.observerCallback && self.observerCallback.call(this, self);
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'clearFromStorage', {
		value: function () {
			localStorage.setItem('debug_history', null);
			self.loadFromStorage();
		},
		enumerable: false,
	});

	self.loadFromStorage();
}

export const debug_history = new debugHistory();

const log = console.log.bind(console);
console.log = (...args) => {
	debug_history.pushToHistory('log', { Timestamp: Date.now(), Params: [...args] });
	log(...args);
};

const warn = console.warn.bind(console);
console.warn = (...args) => {
	debug_history.pushToHistory('warn', { Timestamp: Date.now(), Params: [...args] });
	warn(...args);
};

const error = console.error.bind(console);
console.error = (...args) => {
	debug_history.pushToHistory('error', { Timestamp: Date.now(), Params: [...args] });
	error(...args);
};

const debug = console.debug.bind(console);
console.debug = (...args) => {
	debug_history.pushToHistory('debug', { Timestamp: Date.now(), Params: [...args] });
	debug(...args);
};

const info = console.info.bind(console);
console.info = (...args) => {
	debug_history.pushToHistory('info', { Timestamp: Date.now(), Params: [...args] });
	info(...args);
};
