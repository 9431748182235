import { Outlet } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import BottomMenu from '../Components/BottomMenu';

function Main({ userProfile, userProfilePrivate, betaTestEnabled }) {
	return (
		<>
			<TopMenu userProfile={userProfile} userProfilePrivate={userProfilePrivate} betaTestEnabled={betaTestEnabled} />
			<section
				className={'w-100 bg-body-tertiary'}
				style={{
					height: 'calc(100% - 65px - 65px)',
					marginTop: '65px',
					marginBottom: '65px',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				<Outlet />
			</section>
			<BottomMenu />
		</>
	);
}

export default Main;
